
import BSCSwapAgentABI from "./BSCSwapAgent_ABI.json";
import EthSwapAgentABI from "./EthSwapAgent_ABI.json";
import IBEP20ABI from "./IBEP20_ABI.json";
export const fiero_RPC = "https://rpc1.fieroscan.com";
const base = "https://node.fieresbridge.io";
export const apiURL = `${base}/api/v1/block`;
export const ETHswap = "0x08b7A2a5B3864D74224583D2d01882ae7956277d"; //fiero_swap_address old
export const EthereumChainId = 1;
export const BinanceChainId = 56;
export const AvalancheChainId = 43114;
export const MaticChainId = 137;
export const FieroChainId = 1001;
export const wFiero = "0x8dA89E0901295b38820B201D7e32A124d68B5ca7";

//RPC details of environment networks
export const getRPCDetails = (chainId) => {
  switch (parseInt(chainId)) {
    case EthereumChainId:
      return {
        // url: "https://mainnet.infura.io/v3/",
        url: "https://mainnet.infura.io/v3/e6bba25ea6d748479e16f3c5c1f75599",

        block_url: "https://etherscan.io",
        chainId_hex: "0x1",
        name: "ETH",
        symbol: "ETH",
        network_name: "Ethereum Mainnet",
      };
    case BinanceChainId:
      return {
        url: "https://bsc-dataseed.binance.org/",
        block_url: "https://bscscan.com",
        chainId_hex: "0x38",
        name: "BNB",
        symbol: "BNB",
        network_name: "Binance Mainnet",
      };
    case AvalancheChainId:
      return {
        url: "https://api.avax.network/ext/bc/C/rpc",
        block_url: "https://snowtrace.io/",
        chainId_hex: "0xA86A",
        name: "AVAX",
        symbol: "AVAX",
        network_name: "Avalanche Mainnet",
      };
    case MaticChainId:
      return {
        url: "https://polygon-rpc.com",
        block_url: "https://polygonscan.com/",
        chainId_hex: "0x89",
        name: "MATIC",
        symbol: "MATIC",
        network_name: "Polygon Mainnet",
      };
    case FieroChainId:
      return {
        url: "https://rpc1.fieroscan.com",
        block_url: "https://fieroscan.com",
        chainId_hex: "0x3E9",
        name: "FIERES",
        symbol: "FIERO",
        network_name: "Fieres Mainnet",
      };
  }
};

//Token swap agent addresses
export const getCurrentSwapAddress = (chainId) => {
  switch (parseInt(chainId)) {
    case EthereumChainId:
      return "0xc46C64E21E7E06aDde4a2919Ac984D42D87e523e"; 
    case BinanceChainId:
      return "0xc46c64e21e7e06adde4a2919ac984d42d87e523e";
    case AvalancheChainId:
      return "0xBa0e5551c517B43c24b9dff149C31A9Af7bA51B9";
    case MaticChainId:
      return "0xc46C64E21E7E06aDde4a2919Ac984D42D87e523e";
  }
};

//Coin swap agent addresses
export const getCoinSwapAddress = (chainId) => {
  switch (parseInt(chainId)) {
    case EthereumChainId:
      return "0xB6A06DAd4c73254357F2e9956A605eAc659e1C9E";
    case BinanceChainId:
      return "0x059d62e0dBdFd78f735A2255393d0eca987c6305";
    case AvalancheChainId:
      return "0x57F32d38fd099E1752e5D3ad52ea10a56eD5e502";
    case MaticChainId:
      return "0xc46C64E21E7E06aDde4a2919Ac984D42D87e523e";
  }
};

//Token Basic Details
export const getCoinBasicDetails = (chainId) => {
  switch (parseInt(chainId)) {
    case EthereumChainId:
      return {
        name: "Ethereum Mainnet",
        symbol: "ETH",
        image: "/images/coins/eth.png",
        token: "Ethereum ERC20",
        chain: EthereumChainId,
      };
    case BinanceChainId:
      return {
        name: "Binance Mainnet",
        symbol: "BNB",
        image: "/images/coins/bnb.png",
        token: "Binance BEP20",
        chain: BinanceChainId,
      };
    case AvalancheChainId:
      return {
        name: "Avalanche Mainnet",
        symbol: "AVAX",
        image: "/images/coins/avax-logo.png",
        token: "Avalanche ERC20",
        chain: AvalancheChainId,
      };
    case MaticChainId:
      return {
        name: "Polygon Mainnet",
        symbol: "MATIC",
        image: "/images/coins/polygon-matic-logo.png",
        token: "Polygon ERC20",
        chain: MaticChainId,
      };
    case FieroChainId:
      return {
        name: "Fiero Mainnet",
        symbol: "FIERO",
        image: "/images/coins/fiero.png",
        token: "FIERES ERC20",
        chain: FieroChainId,
      };
  }
};

export const NetworkDetailsList = [
  {
    name: "Ethereum Mainnet",
    symbol: "ETH",
    image: "/images/coins/eth.png",
    token: "Ethereum ERC20",
    chain: EthereumChainId,
  },
  {
    name: "Polygon Mainnet",
    symbol: "MATIC",
    image: "/images/coins/polygon-matic-logo.png",
    token: "Polygon ERC20",
    chain: MaticChainId,
  },
  {
    name: "Avalanche Mainnet",
    symbol: "AVAX",
    image: "/images/coins/avax-logo.png",
    token: "Avalanche ERC20",
    chain: AvalancheChainId,
  },
  {
    name: "Binance Mainnet",
    symbol: "BNB",
    image: "/images/coins/bnb.png",
    token: "Binance BEP20",
    chain: BinanceChainId,
  },
];

export const BSCSwapAgentABIData = BSCSwapAgentABI;
export const EthSwapAgentABIData = EthSwapAgentABI;
export const IBEP20ABIData = IBEP20ABI;
export function generateUniqueAvatar(value) {
  return `https://avatars.dicebear.com/api/identicon/${value}.svg`;
}
