import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  makeStyles,
  DialogContent,
  TextField,
  Avatar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { sortAddress } from "../hooks/operations";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { apiURL, generateUniqueAvatar, getRPCDetails } from "../Utils";
import axios from "axios";
import LaunchIcon from "@material-ui/icons/Launch";
import toast from "react-hot-toast";
const useStyles = makeStyles((theme) => ({
  curvedDialogue: {
    borderRadius: "12px",
    "& .MuiPaper-root": {
      borderRadius: "10px",
      background: "#0a0a01",
      maxWidth: "450px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#8080806b",
      borderRadius: "50px",
    },
    "& .MuiDialogTitle-root": {
      padding: "10px 24px",
    },
    "& .contentBox": {
      margin: "10px 0",
      display: "flex",
      padding: "10px 0 17px",
      cursor: "pointer",
      borderBottom: "1px solid rgba(255, 255, 255, 0.08)",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      color: "#fff",
      width: "100%",
      border: "0",
      padding: "17px 14px 16px",
      fontSize: "13px",
      minWidth: "0",
      background: "none",
      fontWeight: "300",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "1px solid #ffffff5e",
      pointerEvents: "none",
    },
    "& .MuiInput-underline:after": {
      left: "0",
      right: "0",
      bottom: "0",
      content: '""',
      position: "absolute",
      transform: "scaleX(0)",
      transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      borderBottom: "2px solid #fff",
      pointerEvents: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid #f50057",
    },
    "& .newModalBorder": {
      background:
        "linear-gradient(312.78deg, #ffb000 -25.59%, #ff564d 12.16%, #ff0098 39.51%, #5d00c1 55.74%)",
      borderRadius: "10px",
      padding: "2px !important",
    },
    "& .mainbox1": {
      background: "#0a0a01",
      borderRadius: "10px",
      padding: "16px",
      minHeight: "390px",
    },
  },
  mainContentBox: {
    background: "rgba(255, 255, 255, 0.03)",
    padding: "10px",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
  },
  transactionBox: {
    background: "rgba(255, 255, 255, 0.05)",
    padding: "10px",
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    maxHeight: "300px",
    overflowY: "auto",
    overflowX: "hidden",
  },
}));
export default function HistoryModal({
  open,
  close,
  account,
  tranasctionData,
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="sm"
      fullWidth
      className={classes.curvedDialogue}
    >
      <Box className="newModalBorder">
        <Box className="mainbox1">
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h5" style={{ color: "#FFFFFF" }}>
                  Account
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={() => {
                    close();
                  }}
                  style={{ position: "absolute", top: "14px", padding: "0" }}
                >
                  <CloseIcon style={{ color: "#fff" }} />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box className={classes.mainContentBox}>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="center">
                  <Avatar
                    src={account !== null && generateUniqueAvatar(account)}
                    style={{
                      background: "rgba(255, 255, 255, 0.25)",
                      marginRight: "10px",
                    }}
                  />
                  <Typography
                    variant="h4"
                    style={{ color: "#fff", fontSize: "14px" }}
                  >
                    {account && sortAddress(account)}
                  </Typography>
                </Box>
                <Box>
                  <CopyToClipboard text={account}>
                    <IconButton
                      onClick={() => toast.success("Copied successfully.")}
                    >
                      <FilterNoneIcon style={{ color: "#fff" }} />
                    </IconButton>
                  </CopyToClipboard>
                </Box>
              </Box>
            </Box>
            <Box className={classes.transactionBox}>
              <Box>
                {tranasctionData && tranasctionData.length == 0 && (
                  <Typography
                    variant="h4"
                    style={{
                      color: "rgba(255, 255, 255, 0.6)",
                      fontSize: "14px",
                    }}
                  >
                    Your transaction will appear here...
                  </Typography>
                )}
                {tranasctionData &&
                  tranasctionData.map((data,i) => {
                    return (
                      <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        key={i}
                      >
                        <Box>
                          <Typography
                            variant="h4"
                            style={{ color: "#fff", fontSize: "14px" }}
                          >
                            {data &&
                              data.transactionHash &&
                              sortAddress(data.transactionHash)}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <CopyToClipboard text={data?.transactionHash}>
                            <IconButton
                              onClick={() =>
                                toast.success("Copied successfully.")
                              }
                            >
                              <FilterNoneIcon
                                style={{ color: "#fff", marginRight: "10px" }}
                              />
                            </IconButton>
                          </CopyToClipboard>

                          <IconButton
                            onClick={() =>
                              window.open(
                                `${
                                  getRPCDetails(data?.chainId)?.block_url
                                }/tx/${data?.transactionHash}`
                              )
                            }
                          >
                            <LaunchIcon style={{ color: "#fff" }} />
                          </IconButton>
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Box>
    </Dialog>
  );
}
